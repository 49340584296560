.contact-pages {
  height: 110vh;
  width: 100vw;
  display: flex;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  position: absolute;
}

.contact-div {
  height: 40%;
  width: 35%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 100px;
  margin-top: 15%;
}

form {
  display: grid;
  grid-gap: 15px;
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  /* width: 100%; */
  /* padding: 5px; */
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}
.heading h3 {
  color: black;
  /* margin-left: 80%; */
}
.contact-field {
  /* height: 100%;
  width: 50%; */
}
.textcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  /* margin-top: 10%; */
  
}
.textcontainer h3 {
  color: #000;
}
.textcontainer span {
  color: #000;
}

.home-icon {
  display: flex;
  /* height: 100%;
  width: 100%; */
  gap: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 800px) {
  .contact-pages {
    flex-direction: row;
    display: flex;
    height: 100vh;
    width: 300vw;
  }
  /* .contact-div { */
    /* height: 2; */
  /* } */
  .textcontainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 10%;
    margin-top: 15%;
  }
  .textcontainer iframe {
    display: flex;
    height: 100%;
    width: 80%;
  }
  .contact-div{
    height: 30%;
    width: 25%;
  }
  .form-group input,textarea{
    width: 100%;
  }
  textarea{
    height: 100px;
    margin-top: -20px;
  }
}
