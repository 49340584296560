.experience {
  height: 120vh;
  width: 100vw;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  display: flex;
}

.experience h3 {
  margin-left: 30%;
}
.experience-field {
  margin-top: 5%;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cards .red {
  background-color: #f43f5e;
  margin-top: 5%;
}

.cards .blue {
  background-color: #3b82f6;
}

.cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 20%;
  width: 70%;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
  margin-left: 15%;
  margin-top: 15%;
}

.cards .card p.tip {
  font-size: 3em;
  font-weight: 900;
}

.cards .card p.second-text {
  font-size: 1.5em;
  font-weight: normal;
}

.cards .card:hover {
  transform: scale(1.1, 1.1);
}

.cards:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}
@media screen and (max-width: 800px) {
  .experience {
    display: flex;
    height: 95vh;
    width: 300vw;
  }
  .cards .card {
    height: 5%;
    width: 90%;
    margin: 25px;
  }
}
