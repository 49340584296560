.education-page {
  height: 120vh;
  width: 100vw;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  display: flex;
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .glass {
  margin-top: 40%;
  position: relative;
  width: 300px;
  height: 300px;
  background: linear-gradient(#fff2, transparent);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 10px;
  margin: 0 -45px;
  backdrop-filter: blur(10px);
  transform: rotate(calc(var(--r) * 1deg));
  background-color: #023e8a;
  color: #fff;
  padding: 20%;
  text-align: center;
}

.container:hover .glass {
  transform: rotate(0deg);
  margin: 0 10px;
}

.container .glass::before {
  /* content: attr(data-text); */
  position: absolute;
  /* bottom: 0;
  width: 100%;
  height: 40px; */
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .glass svg {
  font-size: 2.5em;
  fill: #000;
}

@media screen and (max-width: 800px) {
  .education-page {
    display: flex;
    height: 50vh;
    width: 300vw;
  }
  .container .glass {
    height: 250px;
    width: 150px;
  }
}
