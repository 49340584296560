.skill-page {
  height: 100vh;
  width: 100vw;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  display: flex;
  padding: 40px;
  /* margin-left: 20%; */
}
.progress-bar {
  color: black;
  margin-top: 4%;
  gap: 5px;
  margin-left: 15%;
}
.name {
  color: black;
  text-align: left;
}
.progress-bar p {
  color: black;
  margin-top: -50px;
  margin-left: 95%;
}
@media screen and (max-width: 800px){
  .skill-page{
    display: flex;
    height: 100vh;
    width: 300vw;
  }
  .progress-bar{
  margin-left: 8%;
  }
}