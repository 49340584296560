* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-page {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  height: 100vh;
  width: 100vw;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
}

.home-name {
  width: 50%;
  flex-direction: column;
  display: flex;
  margin-left: 5%;
}
.home-name p {
  font-size: larger;
}
.images {
  width: 50%;
}

.images img {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  margin-top: 8%;
  margin-left: 10%;
}

.pages {
  background-color: #0077b6;
}

.home-namep {
  font-size: x-large;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .home-page {
    display: flex;
    flex-direction: row;
    height: 50vh;
    width: 300vw;
    display: flex;
    flex-direction: row;
    /* gap: 20px; */
  }
  .home-name {
    display: flex;
    padding-top: 50px;
    margin-left: 100px;
    /* margin-left:; */
    width: 50%;
  }
  .images {
    width: 50%;
  }
  .images img {
    height: 250px;
    width: 250px;
    margin-left: 13%;
  }
}
