.resume-page {
  display: flex;
  height: 120vh;
  width: 100vw;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}
.beautiful-button {
  height: 50px;
  width: 250px;
  margin-left: -125%;
  margin-top: 20%;
}
.beautiful-button {
  position: relative;
  display: inline-block;
  background: linear-gradient(to bottom, #1b1c3f, #4a4e91);
  /* Gradient background */
  color: white;
  /* White text color */
  font-family: "Segoe UI", sans-serif;
  /* Stylish and legible font */
  font-weight: bold;
  font-size: 18px;
  /* Large font size */
  border: none;
  /* No border */
  border-radius: 30px;
  /* Rounded corners */
  padding: 14px 28px;
  /* Large padding */
  cursor: pointer;
  /* Cursor on hover */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
  animation: button-shimmer 2s infinite;
  transition: all 0.3s ease-in-out;
  /* Smooth transition */
}

/* Hover animation */
.beautiful-button:hover {
  background: linear-gradient(to bottom, #2c2f63, #5b67b7);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}

/* Click animation */
.beautiful-button:active {
  transform: scale(0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Shimmer animation */
@keyframes button-shimmer {
  0% {
    background-position: left top;
  }

  100% {
    background-position: right bottom;
  }
}

/* Particle animation */
@keyframes button-particles {
  0% {
    background-position: left top;
  }

  100% {
    background-position: right bottom;
  }
}
.resume-page h1 {
  height: 20%;
  width: 100%;
  margin-left: 500px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .resume-page {
    display: flex;
    height: 100vh;
    width: 300vw;
  }
  .resume-page h1 {
    height: 20%;
    width: 100%;
    margin-left: 450px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .beautiful-button{
    height: 50px;
    width: 200px;
    margin-left: -125%;
    margin-top: 1%;
  }
}
