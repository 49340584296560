.pages {
  display: flex;
  width: 100%;
  height: 100vh;
  /* background-color: #caf0f8; */
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}
/* .Nav-Link {
  font-weight: bolder;
  font-size: larger;
  color: white;
  text-decoration: none;
} */
.MyPortFlio {
  /* background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; */
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 50px;
  /* box-shadow: 0 1px 8px #ddd; */
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}
/* .MyPortFlio:hover{
        background-color: transparent;
    } */
.img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
}
.nav-brand {
  flex-direction: row;
  margin-right: auto;
  font-size: 1.5em;
}
.Nav-Link {
  list-style: none;
  display: inline-block;
  padding: 0 20px;
}
@media screen and (max-width: 800px) {
  .MyPortFlio {
    height: 100%;
    width: 300%;
  }
}
