.project-div{
  height: 100vh;
  width: 100%;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}
.p-tag{
  display: flex;
  margin-top: 15%;
  height: 100vh;
  width: 100%;
}
/* .info-div h3{
  height: 20%;
  width: 30%;
  margin-top: 120%;
} */
@media screen and (max-width: 800px) {
 .project-div{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 300%;
 } 
 
 .img-div{
  display: flex;
  height: 30%;
  width:100%;
  /* flex-grow: 1; */
 }
}

